import React, { Fragment, useContext, useMemo } from 'react'
import { Row, Col } from 'react-bootstrap'
import CurrencyInput from 'react-currency-input';
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import Store from 'store'

import { moneyConverter } from 'components/jobs/create'
import Discount from 'components/general/discount'
import Config from 'utils/config'
import classNames from 'utils/classNames'
import { formatCurrency } from 'utils/formatters'
import styles from './styles.module.css'

export const title = ({ title }) => (
  <Col>
    { !Config.jobBuilderV3 && <h1>New Job Vitals</h1>}
    { Config.jobBuilderV3 && <h1>Step 1: Input Job Vitals</h1>}
    <p>Fill out the parameters for your new Idea job below. These "vitals" will keep you organized and help the Freelancers evalute the job prior to accepting.</p>
  </Col>
)

const renderPct = (amount) => Math.floor(amount * 100) + '%'

const useStyles = makeStyles((theme) => ({
  datePicker: {
    marginTop: 12,
    '& input': {
      backgroundColor: '#F6DDDE',
      fontSize: 32,
      lineHeight: 32,
      padding: 14,
      textTransform: 'uppercase',
    },
    '& fieldset': {
      borderColor: '#000'
    }
  },
}));

export const component = ({ attributes, expertBudget }) => {
  const { dispatch, state } = useContext(Store)
  const classes = useStyles()

  const additionalFeePct = useMemo(() => state.currentUser?.additionalFeePct(), [state.currentUser])

  const additionalFee = attributes.numberOfExperts.value * attributes.perExpertBudget.value * additionalFeePct.discountedAmount
  return (
    <Fragment>

      <h3 className={styles.step_heading} style={{marginBottom: 10}}>
        1. Job Name:
      </h3>
      <div className={styles.validationError}>
        {attributes.title.error.value}
      </div>
      <input
        id="job_attributes.title"
        className={classNames([styles.title_input_v2, styles.hero_input, attributes.title.isValid ? null : styles.invalidInput])}
        value={attributes.title.value}
        tabIndex={1}
        {...attributes.title.eventHandlers} />

      <h3 className={styles.step_heading} style={{marginTop: 50, marginBottom: 10}}>
        2. Set Anticipated Start and End Date <small style={{fontSize: 16, fontWeight: 'normal'}}>(you will be able to change this later)</small>:
      </h3>
      <Row>
        <Col lg={4} md={5} xs={12}>
          <div className={styles.validationError}>
            {attributes.startDate.error.value}
          </div>
          <TextField
            fullWidth
            label="Start date"
            variant="outlined"
            type="date"
            placeholder='MM/DD/YYYY'
            className={classes.datePicker}
            value={attributes.startDate.value}
            InputLabelProps={{
              shrink: true,
            }}
            {...attributes.startDate.eventHandlers}
          />
        </Col>
        <Col lg={2} md={1} xs={0} />
        <Col lg={4} md={5} xs={12}>
          <div className={styles.validationError}>
            {attributes.endDate.error.value}
          </div>
          <TextField
            fullWidth
            label="End date"
            variant="outlined"
            type="date"
            placeholder='MM/DD/YYYY'
            className={classes.datePicker}
            value={attributes.endDate.value}
            InputLabelProps={{
              shrink: true,
            }}
            {...attributes.endDate.eventHandlers}
          />
        </Col>
        <Col lg={2} md={1} xs={0} />
      </Row>

      <h3 className={styles.step_heading} style={{marginTop: 50, marginBottom: 10}}>
        3. Brief Description of the Assignment <small style={{fontSize: 16, fontWeight: 'normal'}}>(the Freelancers will see this in their invitation)</small>:
      </h3>
      <div className={styles.textareaWrapper}>
        <div className={styles.validationError}>
          {attributes.mission.error.value}
        </div>

        <textarea
          tabIndex={2}
          className={classNames([styles.description_input_v2, styles.textarea, attributes.mission.isValid ? null : styles.invalidInput])}
          value={attributes.mission.value}
          {...attributes.mission.eventHandlers} />
      </div>

      {expertBudget && <>
        <h3 className={styles.step_heading} style={{marginTop: 50, marginBottom: 10}}>
          4. Budget <small style={{fontSize: 16, fontWeight: 'normal'}}>(total amount you wish to pay your team of 4 Freelancers, even if it’s $0)</small>:
        </h3>
        <Row>
          <Col lg={5} md={8} xs={12}>
            <div className={styles.validationError}>
              {attributes.perExpertBudget.error.value}
            </div>

            <CurrencyInput
              disabled={attributes.paymentType.value !== 'equal'}
              value={expertBudget}
              className={classNames([styles.budget_input_v2, styles.budgetValueInput])}
              onChangeEvent={(e) => {
                const newEvent = { persist: () => {}, target: { value: (moneyConverter(e.target.value) / attributes.numberOfExperts.value).toString() }}
                attributes.perExpertBudget.eventHandlers.onChange(newEvent)
              }}
              precision="0"
              prefix="$"/>
          </Col>
          <Col lg={7} md={4} xs={0} />
          <Col lg={5} md={8} xs={12}>
            <table className={classNames([styles.budget_table, styles.budget_table_v2])}>
            <tbody>
              <tr>
                <td className={styles.budget_table_label_v2}>Payment per Freelancer</td>
                <td className={classNames([styles.budget_table_amount, styles.budget_table_amount_v2])}>{formatCurrency(attributes.perExpertBudget.value)}</td>
              </tr>
              <tr>
                <td className={styles.budget_table_label_v2}>Handling Fees (<Discount formatter={renderPct} {...additionalFeePct} />)</td>
                <td className={classNames([styles.budget_table_amount, styles.budget_table_amount_v2])}>{formatCurrency(additionalFee)}</td>
              </tr>
              <tr>
                <td className={classNames([styles.budget_table_label_v2, styles.budget_table_total_label])}>
                  Total Budget
                </td>
                <td className={classNames([styles.budget_table_amount, styles.budget_table_amount_v2])}>
                  {formatCurrency(expertBudget + additionalFee)}
                </td>
              </tr>
            </tbody>
            </table>
          </Col>
        </Row>
      </>}
    </Fragment>
  )
}

const budgetValid = ({ perExpertBudget, numberOfExperts, customizedBudget, paymentType }) => {
  let valid = true
  if(paymentType.value === 'equal') {
    if (isNaN(numberOfExperts.value) || numberOfExperts.value < 1) {
      numberOfExperts.error.set("Number of Freelancers must be a valid number that is greater than 0.")
      valid = false
    }
    if (isNaN(perExpertBudget.value)) {
      perExpertBudget.error.set("Budget must be a valid number.")
      valid = false
    }
  } else if(paymentType.value === 'custom') {
    if (isNaN(customizedBudget.value)) {
      customizedBudget.error.set("Budget must be a valid number.")
      valid = false
    }
  }

  //paymentType is always "equal"

  return valid
}

export const validate = (attributes) => {
  let valid = true

  if (!attributes.title.value) {
    attributes.title.error.set("Job title cannot be empty.")
    valid = false
  }
  if (!attributes.mission.value) {
    attributes.mission.error.set("Mission cannot be empty.")
    valid = false
  }
  // Date picker input value is presented as 'MM/DD/YYYY' but formated as 'YYYY-MM-DD'
  if (!moment(attributes.startDate.value, "YYYY-MM-DD", true).isValid()) {
    attributes.startDate.error.set("Start date must be formatted MM/DD/YYYY.")
    valid = false
  }
  if (!moment(attributes.endDate.value, "YYYY-MM-DD", true).isValid()) {
    attributes.endDate.error.set("End date must be formatted MM/DD/YYYY.")
    valid = false
  }

  if(!budgetValid(attributes)) valid = false

  return valid
}
